import { defineStore } from "pinia";
import { getToken } from "@/utils/auth";
interface User {
  "Admin-Token": string;
}
export const useStore = defineStore("main", {
  state: () => {
    return {
      ifPassAuth: false,
    };
  },
  actions: {
    // 验证Token，返回userData
    async authToken() {
      const token = getToken();
      if (token && token != "undefined") {
        this.ifPassAuth = true;
      } else {
        this.ifPassAuth = false;
      }
    },
  },
});
