import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw,
  useRoute,
} from "vue-router";
import { getUniCode, removeToken, removeUniCode } from "@/utils/auth";
import { useStore } from "@/store/login";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/placeHome",
    meta: {
      name: "场所安全信息",
    },
  },
  {
    path: "",
    redirect: "/placeHome",
    meta: {
      name: "场所安全信息",
    },
  },
  {
    path: "/login",
    name: "login",
    meta: {
      name: "场所安全信息",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/login/index.vue"),
  },
  {
    path: "/home",
    name: "home",
    redirect: "/placeHome",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/components/layout/index.vue"),
    children: [
      {
        path: "/placeHome",
        name: "placeHome",
        meta: {
          name: "单位信息",
          toLeft: false,
        },
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/home/index.vue"),
      },
      {
        path: "/checkFrequency",
        name: "checkFrequency",
        meta: {
          name: "检查频次",
          toLeft: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/checkFrequency/index.vue"
          ),
      },
      {
        path: "/safetyDanger",
        name: "safetyDanger",
        meta: {
          name: "安全隐患",
          toLeft: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/safetyDanger/index.vue"
          ),
      },
      {
        path: "/queryPdf",
        name: "queryPdf",
        meta: {
          name: "预览文书",
          toLeft: true,
        },
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/queryPdf/index.vue"),
      },
      {
        path: "/dangerDetail",
        name: "dangerDetail",
        meta: {
          name: "隐患详情",
          toLeft: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/components/dangerDetail/index.vue"
          ),
      },
      {
        path: "/regionCheck",
        name: "regionCheck",
        meta: {
          name: "巡查信息",
          toLeft: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/regionCheck/index.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  let uniqCode = to.query.uniqCode || to.query.id;
  const token = getUniCode();
  if (uniqCode && uniqCode != token) {
    removeToken();
    removeUniCode();
    localStorage.removeItem("placeId");
    localStorage.removeItem("isLogin");
    localStorage.removeItem("isLogin");
  }
  if (useStore().ifPassAuth && to.name == "login") {
    next("/placeHome");
  }
  if (useStore().ifPassAuth && to.name !== "login") {
    next();
  }
  if (!useStore().ifPassAuth && to.name !== "login") {
    next();
  }
  if (!useStore().ifPassAuth && to.name == "login") {
    next();
  }
});

export default router;
