import Cookies from "js-cookie";

const TokenKey = "Admin-Token";

const ExpiresInKey = "Admin-Expires-In";

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token: any) {
  return Cookies.set(TokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function getExpiresIn() {
  return Cookies.get(ExpiresInKey) || -1;
}

export function setExpiresIn(time: any) {
  return Cookies.set(ExpiresInKey, time);
}

export function removeExpiresIn() {
  return Cookies.remove(ExpiresInKey);
}
export function getUniCode() {
  return Cookies.get("uniCode");
}
export function setUniCode(uniCode: string) {
  return Cookies.set("uniCode", uniCode);
}
export function removeUniCode() {
  return Cookies.remove("uniCode");
}
